<template>
  <div style="height: 100%">
    <div>
      <ActionList
        :filters="filters"
        :actions="actions"
        :preloaded-items-list="subscriptions"
        @actionSuccess="getSubscriptions"
        @actionFailure="getSubscriptions"
        :load-items-callback="loadItemsCallback"
        :fixed-filters="fixedFilters"
        :empty-text="$t('noSubscriptionsFound')"
      >
        <template v-slot:item="{ item, index }">
          <SubscriptionListItem
            @selectSubscription="selectSubscription"
            :subscription="item"
            :key="index"
          />
          <v-divider inset />
        </template>
      </ActionList>
    </div>
  </div>
</template>

<script>

  import SubscriptionListItem from "./SubscriptionListItem"
  import ActionList from "./common/list/ActionList"

  export default {
    name: 'SubscriptionList',
    props: {
      loadItemsCallback: {
        type: Function
      },
      fixedFilters: {
        type: Array, 
        default: () => []
      },
      subscriptions: {
        type: Array,
        default: () => []
      }
    },
    components: {
      SubscriptionListItem,
      ActionList
    },
    data()  {
      return {
        length: 50,
        step: 50,
        startIndex: 0,
        isLoadingMore: false,
        filters: [
          {
            key: 'uuid',
            label: this.$t('subscriptionId'), 
            type: 'text', 
            value: null, 
            operator: 'contains'
          },
          {
            key: 'receiver.address.name_line',
            label: this.$t('receiverName'),
            type: 'text',
            value: null,
            operator: 'contains'
          },
          {
            key: 'receiver.email',
            label: this.$t('receiverEmail'),
            type: 'text',
            value: null,
            operator: 'contains'
          },
          {
            key: 'receiver.phone',
            label: this.$t('receiverPhone'),
            type: 'text',
            value: null,
            operator: 'contains'
          },
          {
            key: 'payer.address.name_line',
            label: this.$t('payerName'),
            type: 'text',
            value: null,
            operator: 'contains'
          },
          {
            key: 'payer.email',
            label: this.$t('payerEmail'),
            type: 'text',
            value: null,
            operator: 'contains'
          },
          {
            key: 'payer.phone',
            label: this.$t('payerPhone'),
            type: 'text',
            value: null,
            operator: 'contains'
          },
          { 
            key: 'item.title',
            label: this.$t('item'),
            type: 'text',
            value: null,
            operator: 'contains'
          },
          {
            key: 'label', 
            label: this.$t('label'), 
            type: 'text', 
            value: null, 
            operator: 'contains'
          },
          {
            key: 'expires',
            label: this.$t('expiration'),
            type: 'date',
            value: null, 
            operator: 'equals'
          },
          {
            key: 'nextPayment',
            label: this.$t('nextPayment'),
            type: 'date',
            value: null, 
            operator: 'equals'
          },
          {
            key: 'recurringPrice.amount', 
            label: this.$t('recurringPrice'), 
            type: 'number', 
            value: null,
            operator: 'equals'
          },
          {
            key: 'created',
            label: this.$t('created'),
            type: 'date',
            value: null, 
            operator: 'equals'
          }, 
          {
            key: 'changed',
            label: this.$t('changed'),
            type: 'date',
            value: null, 
            operator: 'equals'
          }, 
        ]
      }
    },
    computed: {
      actions: function() {
        return this.$store.getters.bulkOperationsByRoute('subscriptions')
      },
      slicedSubscriptions() {

        if(this.subscriptions === null) {
          return null
        }

        return this.subscriptions.slice(this.startIndex, this.length)
      }
    },
    methods: {
      loadMore: function() {
        this.isLoadingMore = true
        this.length = this.length + this.step
      },
      getSubscriptions(){
        try {
          return this.$store.dispatch('getSubscriptions', { conditions: [] })
        } catch (error) {
          console.error('[SubscriptionList] getSubscriptions error:', error)
          return []
        }
      },
      selectSubscription(subscription) {
        this.$emit('selectSubscription', subscription)
      }
    }
  }
</script>
